/* Common Imports */

import { styled, useMediaQuery } from "@mui/system";

/* Component Imports */

import { Skeleton, Typography, useTheme } from "@mui/material";

/* Icon Imports */

import { HomeWorkOutlined } from "@mui/icons-material";

/* Styled Components */

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	height: "100%",
	gap: "0.125rem",
	margin: "0rem 0rem 0rem 0rem",
	/* iphone 5 - 320 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
		gap: "0.25rem",
	},
	/*ipad Mini - 768*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		gap: "0.25rem",
	},
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "0.65rem",
	lineHeight: "0.65rem",
	color: theme.palette.text.primary,
	/* iphone 5 - 320 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
		fontSize: "0.65rem",
		lineHeight: "0.65rem",
	},
	/* galaxy S8+ - 360 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
		fontSize: "0.75rem",
		lineHeight: "0.75rem",
	},
	/*iphone XR - 414*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
		fontSize: "0.775rem",
		lineHeight: "0.775rem",
	},
	/*ipad Mini */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		fontSize: "0.825rem",
		lineHeight: "0.825rem",
	},
	/* ipad Air*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		fontSize: "0.875rem",
		lineHeight: "0.875rem",
	},
	/*910*/
	[theme.breakpoints.up("sm910")]: {
		fontSize: "0.95rem",
		lineHeight: "0.95rem",
	},
	/* 1080p breakpoint */
	[theme.breakpoints.up("xl")]: {
		fontSize: "1rem",
		lineHeight: "1rem",
	},
}));

const CustomIcon = styled(HomeWorkOutlined)(({ theme }) => ({
	color: "#b05911",
	margin: "0rem 0rem 0rem 0rem",
	fontSize: "0.65rem",
	/* iphone 5 - 320 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
		fontSize: "0.65rem",
	},
	/* galaxy S8+ - 360 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
		fontSize: "0.75rem",
	},
	/*iphone XR - 414*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
		fontSize: "0.775rem",
	},
	/*ipad Mini */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		fontSize: "0.825rem",
	},
	/* ipad Air*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		fontSize: "0.875rem",
	},
	/*910*/
	[theme.breakpoints.up("sm910")]: {
		fontSize: "0.95rem",
	},
	/* 1080p breakpoint */
	[theme.breakpoints.up("xl")]: {
		fontSize: "1rem",
	},
}));

const SkeletonSection = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));

const GridCardAreaAndConfig = ({ loading, propertyType }: { loading: boolean; propertyType: Array<string> }) => {
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up("md1190"));

	if (loading) {
		return (
			<SkeletonSection>
				<Skeleton
					animation="wave"
					variant="text"
					width="30%"
					height="auto"
					style={{ marginBottom: "0.5rem" }}
				/>
				<Skeleton
					animation="wave"
					variant="text"
					width="30%"
					height="auto"
					style={{ marginBottom: "0.5rem" }}
				/>
			</SkeletonSection>
		);
	}

	return (
		<Container>
			<CustomIcon />
			<Text variant="body1">
				{propertyType[0]?.length > (isDesktop ? 22 : 18)
					? propertyType[0].substring(0, isDesktop ? 22 : 18) + "..."
					: propertyType[0]}
			</Text>
		</Container>
	);
};

export default GridCardAreaAndConfig;
