/* Common Imports */

import { styled, useMediaQuery } from "@mui/system";
import React from "react";
/* Redux Imports */

import { ObjectId } from "mongodb";
import StateInterface from "../../../../../redux-magic/state-interface";

/* Component Imports */

import { IconButton, SvgIcon, useTheme } from "@mui/material";
import DeleteListingPropertyModal from "../../../../modals/PropertyModal/DeleteListingProperty";
/* Icon Imports */

import { Bookmark, BookmarkTwoTone } from "@mui/icons-material";
import { useRouter } from "next/router";
import PropertyBoostedIcon from "../../../../../public/images/icons/boosting/PropertyBoostedIcon.svg";
import { addSaveEntityBusinessProfileThunk } from "../../../../../redux-magic/thunks";

/* Styled Components */

const Container = styled("div")(({ theme }) => ({
	objectFit: "cover",
	width: "100%",
	borderRadius: "12px 12px 0px 0px",
	position: "relative",
}));

const Image = styled("img")(({ theme }) => ({
	display: "block",
	objectFit: "cover",
	height: "5rem",
	borderRadius: "10px 10px 0px 0px",
	/* XS breakpoint - 280.05 */
	[theme.breakpoints.up("xs")]: {
		height: "5rem",
	},
	/* iphone 5 - 320 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
		height: "5rem",
	},
	/* galaxy S8+ - 360 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
		height: "5.5rem",
	},
	/* iphone 12 pro and pixel - 390 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 108.95)]: {
		height: "5.75rem",
	},
	/* iphone XR - 414 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
		height: "6rem",
	},
	/* iphone 14 pro max - 430 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 149)]: {
		height: "6.25rem",
	},
	/* 457 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 176)]: {
		height: "6.75rem",
	},
	/* 485 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 204)]: {
		height: "7.25rem",
	},
	/* 512 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 231)]: {
		height: "7.5rem",
	},
	/* 540 */
	[theme.breakpoints.up("xsPlus")]: {
		height: "7.75rem",
	},
	/* 570  ~~3 cards start~~ */
	[theme.breakpoints.up(theme.breakpoints.values.xsPlus + 30)]: {
		height: "5.5rem",
	},
	/* 600  ~~2 cards start~~ */
	[theme.breakpoints.up("sm")]: {
		height: "7.75rem",
	},
	/* 642 ~~3 cards start~~ */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 42)]: {
		height: "6rem",
	},
	/* 684 */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 84)]: {
		height: "6.25rem",
	},
	/* ipad Mini - 768 */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		height: "7rem",
	},
	/* ipad Air - 820 */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		height: "8rem",
	},
	/* 860 */
	[theme.breakpoints.up("sm860")]: {
		height: "8.25rem",
	},
	/* 910 */
	[theme.breakpoints.up("sm910")]: {
		height: "8.5rem",
	},
	/* 960 */
	[theme.breakpoints.up("md")]: {
		height: "8.75rem",
	},
	/* iPadPro - 1024 */
	[theme.breakpoints.up(theme.breakpoints.values.md + 64)]: {
		height: "9.25rem",
	},
	/* 1107 */
	[theme.breakpoints.up(theme.breakpoints.values.md + 147)]: {
		height: "9.5rem",
	},
	/* 1190 ~~Desktop Layout Start~~ */
	[theme.breakpoints.up("md1190")]: {
		height: "8rem",
	},
	/* 720p breakpoint */
	[theme.breakpoints.up("lg")]: {
		height: "8.5rem",
	},
	/* 1080p 125% breakpoint */
	[theme.breakpoints.up(theme.breakpoints.values.lg + 150)]: {
		height: "9rem",
	},
	/* 4k breakpoint 3840p */
	[theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
		height: "9rem",
	},
}));

const BookmarkIconButton = styled(IconButton)(({ theme }) => ({
	background: "transparent",
	padding: "0rem",
	width: "0.825rem",
	height: "0.825rem",
	borderRadius: "0px",
	cursor: "pointer",
	"&:hover": {
		background: "transparent",
	},
}));
// const BoltContainer = styled("div")(({ theme }) => ({
// 	background: "white",
// 	"&:hover": {
// 		background: "white",
// 	},
// }));

/*

 & Setting a constant image for when the image is not available or something goes wrong

*/

const fallbackImage: string = "/images/uploads/property.jpg";

const GridImage = ({
	_id,
	slug,
	images,
	dispatch,
	profile_context,
	session,
	loading,
	saved_content,
	created_by,
	assigned_to,
	isEditor,
	boosting,
	transaction_type,
}: {
	_id: ObjectId | string;
	slug: string;
	images: string;
	dispatch: Function;
	profile_context: StateInterface["profile_context"];
	session: StateInterface["redux_session"];
	loading: boolean;
	saved_content: boolean;
	created_by?: any;
	assigned_to?: any;
	isEditor?: boolean;
	boosting?: any;
	transaction_type: string;
}) => {
	const theme = useTheme();

	const smDevice = useMediaQuery(theme.breakpoints.down("sm"));

	/*

	& Check if page is loaded. i loaded set to true. if page loads we use regular image. if not we use lqip image.

	*/
	// const [isPageLoaded, SetIsPageLoaded] = React.useState<boolean>(false);

	// React.useEffect(() => {
	// 	SetIsPageLoaded(true);
	// }, [loading]);

	/* Pathname */

	const router = useRouter();

	const pathname = React.useRef(router).current;

	/*

	& Next, Let's define a local state to handle menu.

	*/

	// const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	/*

	& Next, Let's define a constant to hold anchorEl state.

	*/

	// const [open, setOpen] = React.useState(false);
	const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false);

	const handleCloseDeleteModal = () => {
		setOpenDeleteModal(false);
	};

	/*

	& Next, Let's descride a function to chnage anchorEl state.

	*/

	// const handleClick = (event: any) => {
	// 	event.preventDefault();
	// 	setAnchorEl(event.currentTarget);
	// 	setOpen(true);
	// };

	// const handleDelete = async () => {
	// 	setOpenDeleteModal(true);
	// 	setAnchorEl(null);
	// 	setOpen(false);
	// };
	// const handleClose = () => {
	// 	setAnchorEl(null);
	// 	setOpen(false);
	// };
	// const [isHovered, setHovered] = React.useState(false);

	/*

	& Next, let's declare  local state to handle bookmark.

	*/

	const [bookmark, setBookmark] = React.useState(saved_content);

	React.useEffect(() => {
		setBookmark(saved_content);
	}, [saved_content]);

	const submit = async () => {
		dispatch(
			addSaveEntityBusinessProfileThunk({
				is_business_profile: profile_context?.is_business_profile,
				user_id: profile_context?.user_id,
				business_profile_id: profile_context?.business_profile_id,
				entity_id: _id,
				entity_type: "property",
				add_remove: !bookmark ? "add" : "remove",
				url: pathname.asPath ? pathname.asPath : "",
				sessionId: (session && session.session_id) || "",
			}),
		);
		setBookmark(!bookmark);
	};

	// if (loading) {
	// 	return (
	// 		<React.Fragment>
	// 			<Skeleton
	// 				animation="wave"
	// 				variant="rectangular"
	// 				width="100%"
	// 				height="100%"
	// 				style={{ borderRadius: "16px", margin: "0rem" }}
	// 			/>
	// 		</React.Fragment>
	// 	);
	// }

	/* Boosting end date */

	// const date = new Date(boosting?.ends_at);

	/* Date , Month and year format*/

	// const formattedDate = `
	// 		${date.getDate()}
	// 		${date.toLocaleString("en-US", {
	// 				month: "short",
	// 			})}
	// 		${date.getFullYear().toString().slice(2)}`;

	// const BoostPaper = (
	// 	<Paper
	// 		sx={{
	// 			position: "absolute",
	// 			top: 13,
	// 			left: 13,
	// 			zIndex: 3,
	// 			display: "flex",
	// 			justifyContent: "center",
	// 			alignItems: "space-between",
	// 			height: "2.25rem",
	// 			padding: "0.43rem",
	// 			flexDirection: "column",
	// 			overflow: "hidden",
	// 			borderRadius: "16px",
	// 			boxShadow: "none",
	// 			backgroundColor: "white",
	// 		}}
	// 	>
	// 		<BoltContainer
	// 			// onClick={(event: any) => {
	// 			//   event.preventDefault();
	// 			//   session === null ? window.open("/auth/login") : submit();
	// 			// }}
	// 			sx={{
	// 				display: "flex",
	// 				width: !isHovered ? "1.5rem" : "11rem",
	// 				height: "2rem",
	// 				gap: "0.5rem",
	// 				overflow: "hidden",
	// 				borderRadius: "16px",
	// 				transition: "width 0.25s ease-in-out",
	// 			}}
	// 		>
	// 			<Bolt sx={{ color: theme.palette.primary.main, fontSize: "0.625rem" }} />
	// 			<Typography
	// 				sx={{
	// 					color: "black",
	// 				}}
	// 			>
	// 				{session === null ? "Boosted Property" : "Ends on " + formattedDate}
	// 			</Typography>
	// 		</BoltContainer>
	// 	</Paper>
	// );

	const signIn = () => {
		window.location.href =
			(process.env.PRODUCTION_URL || "") +
			"loading.html?callbackUrl=" +
			encodeURIComponent(
				(process.env.PRODUCTION_URL || "") + "auth/login?callbackUrl=" + encodeURIComponent(window.location.href),
			);
	};

	return (
		<React.Fragment>
			{/* Delete Modal */}

			<DeleteListingPropertyModal
				open={openDeleteModal}
				handleClose={handleCloseDeleteModal}
				creator_user_id={created_by?.userId}
				creator_business_profile_id={created_by?.business_profile_id}
				propertyId={_id}
				session={session}
				isEditor={isEditor ? isEditor : false}
				profile_context={profile_context}
				dispatch={dispatch}
				property_type={"property"}
			/>

			<Container>
				{/* Bookmark Icon */}

				<BookmarkIconButton
					id={"property_card_bookmark_icon"}
					disableFocusRipple
					disableRipple
					disableTouchRipple
					onClick={(event: any) => {
						event.preventDefault();
						event.stopPropagation();
						event.nativeEvent.stopImmediatePropagation();
						session === null ? signIn() : submit();
					}}
					sx={{
						position: "absolute",
						top: 10,
						right: 10,
					}}
				>
					{!bookmark ? (
						<BookmarkTwoTone
							sx={{
								color: "#FFFFFF",
								fontSize: smDevice ? "1.75rem" : "1.75rem",
							}}
						/>
					) : (
						<Bookmark
							sx={{
								color: theme.palette.primary.main,
								fontSize: smDevice ? "1.5rem" : "1.5rem",
								stroke: "#FFFFFF",
								strokeWidth: 2,
							}}
						/>
					)}
				</BookmarkIconButton>

				{/* Boost Icon */}

				{/* {boosting?.active === true ? (
					<div
						onMouseEnter={() => setHovered(true)}
						onMouseLeave={() => setHovered(false)}
						style={{
							position: "absolute",

							[theme.breakpoints.down("sm")]: {
								display: "none",
							},
						}}
					>
						<Box sx={{ width: "100%" }}>
							<div>{BoostPaper}</div>
						</Box>
					</div>
				) : null} */}

				{/* Options Menu */}

				{/* {isEditor ? (
					<ThreeDotIconButton
						onClick={(event: any) => {
							event.preventDefault();
							session === null ? signIn() : handleClick(event);
						}}
						sx={{
							position: "absolute",
							top: 12,
							right: 3,
							zIndex: 4,
						}}
					>
						<MoreVertOutlined sx={{ color: "#fafafa" }} />
					</ThreeDotIconButton>
				) : null}

				<Menu
					id="basic-menu"
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					transformOrigin={{ horizontal: "right", vertical: "top" }}
					anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
					disableScrollLock={true}
				>
					{session?.user?.dbUser?._id === created_by?.user_id ||
					session?.user?.dbUser?.access_level.includes("super_admin") ||
					(session?.user?.dbUser?.access_level.includes("admin") &&
						session?.user?.dbUser?.access_level.includes("business_profiles") &&
						assigned_to?.includes(session?.user?.dbUser?._id)) ? (
						<MenuItem
							onClick={() => {
								window.open(`/properties/${slug}`, "_self");
								handleClose();
							}}
						>
							Edit property
						</MenuItem>
					) : null}

					{session?.user?.dbUser?._id === created_by?.user_id ||
					session?.user?.dbUser?.access_level.includes("super_admin") ||
					(session?.user?.dbUser?.access_level.includes("admin") &&
						session?.user?.dbUser?.access_level.includes("business_profiles") &&
						assigned_to?.includes(session?.user?.dbUser?._id)) ? (
						<MenuItem onClick={handleDelete}>Delete property</MenuItem>
					) : null}
				</Menu> */}
				{boosting && boosting.active === true && (
					<SvgIcon
						component={PropertyBoostedIcon}
						inheritViewBox={false}
						viewBox="0 0 17 16"
						sx={{ position: "absolute", width: "1.5rem", height: "1.5rem", top: "0.25rem", left: "0.25rem" }}
					/>
				)}
				<Image
					src={images && images !== undefined && images !== "" ? images : fallbackImage}
					alt="Property Image"
					referrerPolicy="no-referrer"
					loading="lazy"
					width="100%"
				/>
				{/* <CustomChip
					label={
						transaction_type && transaction_type === "buy"
							? "Sale"
							: transaction_type.charAt(0).toUpperCase() + transaction_type.slice(1)
					}
					transaction_type={transaction_type}
				/> */}
			</Container>
		</React.Fragment>
	);
};

export default GridImage;
