/* Common Imports */

import React from "react";

/* Component Imports */

import { Skeleton, Typography } from "@mui/material";
import { styled, useMediaQuery, useTheme } from "@mui/system";

/* Styled Components */

const Heading = styled(Typography)(({ theme }) => ({
	fontWeight: 400,
	fontSize: "0.7rem",
	lineHeight: "0.7rem",
	margin: "0.25rem 0rem 0.25rem 0rem",
	/* iphone 5 - 320 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
		fontSize: "0.7rem",
		lineHeight: "0.7rem",
	},
	/* galaxy S8+ - 360 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
		fontSize: "0.775rem",
		lineHeight: "0.775rem",
	},
	/* iphone se - 375 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 94)]: {
		fontSize: "0.825rem",
		lineHeight: "0.825rem",
	},
	/*iphone XR - 414*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
		fontSize: "0.875rem",
		lineHeight: "0.875rem",
	},
	/* 570  ~~3 cards start~~ */
	[theme.breakpoints.up(theme.breakpoints.values.xsPlus + 30)]: {
		fontSize: "0.75rem",
		lineHeight: "0.75rem",
	},
	/* 600  ~~2 cards start~~ */
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
		lineHeight: "0.875rem",
	},
	/* 642 ~~3 cards start~~ */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 42)]: {
		fontSize: "0.775rem",
		lineHeight: "0.775rem",
	},
	/* 684 */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 84)]: {
		fontSize: "0.825rem",
		lineHeight: "0.825rem",
	},
	/*ipad Mini */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		fontSize: "0.875rem",
		lineHeight: "0.875rem",
	},
	/* ipad Air*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		fontSize: "0.95rem",
		lineHeight: "0.95rem",
	},
	/*860*/
	[theme.breakpoints.up("sm860")]: {
		fontSize: "0.95rem",
		lineHeight: "0.95rem",
		margin: "0.5rem 0rem 0.5rem 0rem",
	},
	/*910*/
	[theme.breakpoints.up("sm910")]: {
		fontSize: "1.125rem",
		lineHeight: "1.125rem",
	},
	/* 1080p breakpoint */
	[theme.breakpoints.up("xl")]: {
		fontSize: "1.125rem",
		lineHeight: "1.125rem",
	},
}));

const GridCardTitle = ({ loading, title }: { loading?: boolean; title?: string }) => {
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up("md1190"));

	if (loading) {
		return (
			<Skeleton
				animation="wave"
				variant="text"
				width="75%"
				height="auto"
			/>
		);
	}

	return (
		<React.Fragment>
			<Heading variant="body1">
				{title ? (title.length < (isDesktop ? 22 : 18) ? title : title?.substring(0, isDesktop ? 22 : 18) + "...") : ""}
			</Heading>
		</React.Fragment>
	);
};

export default GridCardTitle;
