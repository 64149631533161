/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

import { ObjectId } from "mongodb";
import StateInterface from "../../../../redux-magic/state-interface";
import { ServicePrice } from "../../../../redux-magic/sub-interfaces/sub-interfaces";

/* Component Imports */

// import ServiceCardCTA from "./sub-components/ServiceCardCTA";
import ServiceCardImage from "./sub-components/ServiceCardImage";
import ServiceCardLocation from "./sub-components/ServiceCardLocation";
import ServiceCardTitle from "./sub-components/ServiceCardTitle";
import ServiceCardType from "./sub-components/ServiceCardType";
import ServiceProviderName from "./sub-components/ServiceProviderName";
import { useRouter } from "next/router";

/* Styled Components */

const ServiceLink = styled("a", {
	shouldForwardProp: (prop) => prop !== "source",
})<{ source: string }>(({ theme, source }) => ({
	textDecoration: "none",
	width: source !== "carousel" ? "100%" : "auto",
	color: theme.palette.mode == "dark" ? "#fff" : "#000",
	"&:hover": {
		textDecoration: "none",
	},
	/* iphone 5 - 320 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
		width: source !== "carousel" ? "48%" : "auto",
	},
	/* 570  ~~3 cards start~~ */
	[theme.breakpoints.up(theme.breakpoints.values.xsPlus + 30)]: {
		width: source !== "carousel" ? "31.25%" : "auto",
	},
	/* 600  ~~2 cards start~~ */
	[theme.breakpoints.up("sm")]: {
		width: source !== "carousel" ? "48%" : "auto",
	},
	/* 642 ~~3 cards start~~ */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 42)]: {
		width: source !== "carousel" ? "31.25%" : "auto",
	},
	/* 960 */
	[theme.breakpoints.up("md")]: {
		width: source !== "carousel" ? "32%" : "auto",
	},
	/* 1190 ~~Desktop Layout Start~~ */
	[theme.breakpoints.up("md1190")]: {
		width: source !== "carousel" ? "31.75%" : "auto",
	},
	/* 720p breakpoint */
	[theme.breakpoints.up("lg")]: {
		width: source !== "carousel" ? "32%" : "auto",
	},
	/* 2K breakpoint  2560 ~~4 cards start~~ */
	[theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
		width: source !== "carousel" ? "23.75%" : "auto",
	},
}));

const ServiceCardContainer = styled("div", {
	shouldForwardProp: (prop) => prop !== "source" && prop !== "isEditor",
})<{ source: string; isEditor: boolean }>(({ theme, source, isEditor }) => ({
	background: theme.palette.background.paper,
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "10px",
	objectFit: "contain",
	width: source !== "carousel" ? "100%" : "10rem",
	// height: "29.65rem",
	scrollSnapAlign: "start",
	flexShrink: 1,
	flexGrow: 1,
	"@media (pointer: fine)": {
		transition: "all 0.2s ease",
		"&:hover": {
			// boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
			boxShadow: "0px 4px 18px 0px rgba(0, 0, 0, 0.05)",
			transform: "scale(1.01)",
		},
	},

	/* XXS breakpoint - <280 */
	[theme.breakpoints.up("xxs")]: {
		// width: "6.65rem",
		// height: source === "business_profile_listings" && isEditor ? "11.75rem" : "9.5rem",
	},
	/* XS breakpoint - 280.05 */
	[theme.breakpoints.up("xs")]: {
		// width: "16rem",
		// height: source === "business_profile_listings" && isEditor ? "16.25rem" : "14rem",
	},
	/* iphone 5 - 320 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
		width: source !== "carousel" ? "100%" : "9rem",
		// height: source === "business_profile_listings" && isEditor ? "13rem" : "10.75rem",
	},
	/* galaxy S8+ - 360 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
		width: source !== "carousel" ? "100%" : "10.125rem",
		// height: source === "business_profile_listings" && isEditor ? "14rem" : "11.75rem",
	},
	/* iphone se - 375 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 94)]: {
		width: source !== "carousel" ? "100%" : "10.5rem",
		// height: source === "business_profile_listings" && isEditor ? "14rem" : "11.75rem",
	},
	/* iphone 12 pro and pixel - 390 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 108.95)]: {
		width: source !== "carousel" ? "100%" : "11rem",
		// height: source === "business_profile_listings" && isEditor ? "14.25rem" : "12rem",
	},
	/* iphone XR - 414 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
		width: source !== "carousel" ? "100%" : "11.65rem",
		// height: source === "business_profile_listings" && isEditor ? "14.75rem" : "12.5rem",
	},
	/* iphone 14 pro max - 430 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 149)]: {
		width: source !== "carousel" ? "100%" : "12.1rem",
		// height: source === "business_profile_listings" && isEditor ? "15rem" : "12.75rem",
	},
	/* 457 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 176)]: {
		width: source !== "carousel" ? "100%" : "13rem",
		// height: source === "business_profile_listings" && isEditor ? "15.5rem" : "13.25rem",
	},
	/* 485 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 204)]: {
		width: source !== "carousel" ? "100%" : "13.75rem",
		// height: source === "business_profile_listings" && isEditor ? "16rem" : "13.75rem",
	},
	/* 512 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 231)]: {
		width: source !== "carousel" ? "100%" : "14.5rem",
		// height: source === "business_profile_listings" && isEditor ? "16.25rem" : "14rem",
	},
	/* 540 */
	[theme.breakpoints.up("xsPlus")]: {
		width: source !== "carousel" ? "100%" : "14.75rem",
		// height: source === "business_profile_listings" && isEditor ? "16.5rem" : "14.25rem",
	},
	/* 570  ~~3 cards start~~ */
	[theme.breakpoints.up(theme.breakpoints.values.xsPlus + 30)]: {
		width: source !== "carousel" ? "100%" : "10.125rem",
		// height: source === "business_profile_listings" && isEditor ? "14rem" : "11.75rem",
	},
	/* 600  ~~2 cards start~~ */
	[theme.breakpoints.up("sm")]: {
		width: source !== "carousel" ? "100%" : "15.25rem",
		// height: source === "business_profile_listings" && isEditor ? "16.5rem" : "14.25rem",
	},
	/* 642 ~~3 cards start~~ */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 42)]: {
		width: source !== "carousel" ? "100%" : "10.65rem",
		// height: source === "business_profile_listings" && isEditor ? "14.5rem" : "12.25rem",
	},
	/* 684 */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 84)]: {
		width: source !== "carousel" ? "100%" : "11.5rem",
		// height: source === "business_profile_listings" && isEditor ? "14.75rem" : "12.5rem",
	},
	/* 726 */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 126)]: {
		width: source !== "carousel" ? "100%" : "12.35rem",
		// height: source === "business_profile_listings" && isEditor ? "14.75rem" : "12.5rem",
	},
	/* ipad Mini - 768 */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		width: source !== "carousel" ? "100%" : "13.25rem",
		// height: source === "business_profile_listings" && isEditor ? "16.5rem" : "14.25rem",
	},
	/* ipad Air - 820 */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		width: source !== "carousel" ? "100%" : "14.25rem",
		// height: source === "business_profile_listings" && isEditor ? "17.75rem" : "15.5rem",
	},
	/* 860 */
	[theme.breakpoints.up("sm860")]: {
		width: source !== "carousel" ? "100%" : "15.25rem",
		// height: source === "business_profile_listings" && isEditor ? "18.75rem" : "16.5rem",
	},
	/* 910 */
	[theme.breakpoints.up("sm910")]: {
		width: source !== "carousel" ? "100%" : "16.25rem",
		// height: source === "business_profile_listings" && isEditor ? "19.5rem" : "17.25rem",
	},
	/* 960 */
	[theme.breakpoints.up("md")]: {
		width: source !== "carousel" ? "100%" : "16.5rem",
		// height: source === "business_profile_listings" && isEditor ? "19.5rem" : "17.25rem",
	},
	/* iPadPro - 1024 */
	[theme.breakpoints.up(theme.breakpoints.values.md + 64)]: {
		width: source !== "carousel" ? "100%" : "17.75rem",
		// height: source === "business_profile_listings" && isEditor ? "20rem" : "17.75rem",
	},
	/* 1107 */
	[theme.breakpoints.up(theme.breakpoints.values.md + 147)]: {
		width: source !== "carousel" ? "100%" : "19.5rem",
		// height: source === "business_profile_listings" && isEditor ? "20.25rem" : "18rem",
	},
	/* 1190 ~~Desktop Layout Start~~ */
	[theme.breakpoints.up("md1190")]: {
		width: source !== "carousel" ? "100%" : "15.65rem",
		// height: source === "business_profile_listings" && isEditor ? "21.25rem" : "16.5rem",
	},
	/* 720p breakpoint */
	[theme.breakpoints.up("lg")]: {
		width: source !== "carousel" ? "100%" : "16.95rem",
		// height: source === "business_profile_listings" && isEditor ? "21.5rem" : "16.75rem",
	},
	/* 1080p 125% breakpoint */
	[theme.breakpoints.up(theme.breakpoints.values.lg + 150)]: {
		width: source !== "carousel" ? "100%" : "17.95rem",
		// height: source === "business_profile_listings" && isEditor ? "22rem" : "17.25rem",
	},
	/* 1080p breakpoint */
	[theme.breakpoints.up("xl")]: {
		width: source !== "carousel" ? "100%" : "17.45rem",
		// height: source === "business_profile_listings" && isEditor ? "22.25rem" : "17.5rem",
	},
	/* 2K breakpoint  2560p */
	[theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
		width: source !== "carousel" ? "100%" : "15.45rem",
		// height: source === "business_profile_listings" && isEditor ? "22.25rem" : "17.5rem",
	},
	/* 4k breakpoint 3840p */
	[theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
		width: source !== "carousel" ? "100%" : "16.95rem",
		// height: source === "business_profile_listings" && isEditor ? "22.25rem" : "17.5rem",
	},
}));

const ServiceCardInfoContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	padding: "0.5rem 0.5rem 0.5rem 0.5rem",
	margin: "0rem 0rem 0rem 0rem",
	width: "100%",
	height: "100%",
	borderRadius: "0px 0px 16px 16px",
	cursor: "pointer",
	/* ipad Mini - 768 */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		padding: "0.75rem 0.75rem 0.75rem 0.75rem",
	},
}));

const SubTextContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	margin: "0.45rem 0rem 0rem 0rem",
	width: "100%",
	cursor: "pointer",
	gap: "0.5rem",
	/* ipad Mini - 768 */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		gap: "0.65rem",
	},
	/* 860 */
	[theme.breakpoints.up("sm860")]: {
		gap: "0.75rem",
	},
	/* 910 */
	[theme.breakpoints.up("sm910")]: {
		gap: "0.85rem",
	},
	/* 1190 ~~Desktop Layout Start~~ */
	[theme.breakpoints.up("md1190")]: {
		gap: "0.75rem",
	},
}));

// const PriceAndTransactionContainer = styled("div")(({ theme }) => ({
// 	display: "flex",
// 	flexDirection: "row",
// 	justifyContent: "space-between",
// 	alignItems: "center",
// 	width: "100%",
// }));

// const CustomChip = styled(Chip, {
// 	shouldForwardProp: (prop) => prop !== "transaction_type",
// })<{ transaction_type: string }>(({ theme, transaction_type }) => ({
// 	height: "1.125rem",
// 	padding: "0rem",
// 	// fontWeight: 500,
// 	fontSize: "0.625rem",
// 	letterSpacing: "0.02857em",
// 	borderRadius: "8px",
// 	backgroundColor:
// 		transaction_type === "buy"
// 			? "#b7efc5"
// 			: transaction_type === "rent"
// 				? "#ffc6ac"
// 				: transaction_type === "resale"
// 					? "#caf0f8"
// 					: "#b7efc5",
// 	color:
// 		transaction_type === "buy"
// 			? "#1e4620"
// 			: transaction_type === "rent"
// 				? "#4c2708"
// 				: transaction_type === "resale"
// 					? "#212121"
// 					: "#1e4620",
// }));

const ServiceCard = ({
	id,
	profile_context,
	session,
	dispatch,
	loading,
	_id,
	slug,
	transaction_type,
	services_type,
	title,
	images,
	location,
	published_status,
	developer,
	price,
	saved_content,
	created_by,
	assigned_to,
	isEditor,
	boosting,
	preview,
	show_contact_details,
	source,
}: {
	id?: string;
	profile_context: StateInterface["profile_context"];
	session?: StateInterface["redux_session"];
	dispatch: Function;
	loading?: boolean;
	_id?: ObjectId | string;
	slug?: string;
	transaction_type?: string;
	services_type?: Array<string>;
	title?: string;
	images?: string;
	published_status?: string;
	location?: string;
	developer?: string;
	price?: ServicePrice;
	saved_content?: boolean;
	created_by?: any;
	assigned_to?: StateInterface["single_business_profile"]["business_profile"]["assigned_to"];
	isEditor?: boolean | undefined;
	boosting?: StateInterface["service"]["boost_information"];
	preview?: boolean;
	show_contact_details?: boolean;
	source?: string;
}) => {
	const router = useRouter();

	let BoostingCheck = boosting?.active ? true : false;

	return (
		<React.Fragment>
			<ServiceLink
				id={
					(router.asPath === "/"
						? "home_page_"
						: router.asPath.includes("/search?")
							? "search_page_"
							: router.asPath.includes("services")
								? "services_page_"
								: "gg") +
					(router.asPath.includes("/search?") ? "services_" : "top_services_") +
					slug
				}
				source={source ? source : ""}
				href={`/${router.locale}/services/${slug}`}
				rel="noopener"
				target="_self"
				referrerPolicy="no-referrer"
				title={title}
				sx={{
					direction: router.locale === "ar-AE" ? "rtl" : "ltr",
				}}
			>
				<ServiceCardContainer
					source={source ? source : ""}
					isEditor={isEditor !== undefined ? isEditor : false}
					id={id}
				>
					{/* Image */}

					<ServiceCardImage
						_id={_id as string}
						slug={slug as string}
						images={images as string}
						dispatch={dispatch}
						session={session ? session : undefined}
						profile_context={profile_context as StateInterface["profile_context"]}
						loading={loading as boolean}
						saved_content={saved_content as boolean}
						created_by={created_by as any}
						assigned_to={assigned_to as any}
						isEditor={isEditor as boolean}
						boosting={boosting as any}
						transaction_type={transaction_type as string}
					/>

					{/* Title */}

					<ServiceCardInfoContainer>
						{/* <PriceAndTransactionContainer> */}
						{/* Price */}

						{/* <ServiceCardPrice
								loading={loading as boolean}
								price={price as ServicePrice}
							/> */}

						{/* Transaction Type Chip */}

						{/* <CustomChip
								label={
									transaction_type && transaction_type === "buy"
										? "Sale"
										: transaction_type.charAt(0).toUpperCase() + transaction_type.slice(1)
								}
								transaction_type={transaction_type}
							/> */}
						{/* </PriceAndTransactionContainer> */}

						<ServiceCardTitle
							loading={loading as boolean}
							title={title !== "" || undefined ? (title as string) : "Unknown Title"}
						/>

						<SubTextContainer>
							{/* Location */}

							<ServiceCardLocation
								loading={loading as boolean}
								location={location !== "" || undefined ? (location as string) : "Unknown Location"}
							/>

							{/* Service Type */}

							<ServiceCardType
								loading={loading as boolean}
								servicesType={services_type ? services_type : []}
							/>

							{/* Config  */}

							<ServiceProviderName
								loading={loading as boolean}
								serviceProviderName={developer as string}
							/>

							{/* CTA  */}

							{/* {source === "business_profile_listings" && isEditor ? (
								<ServiceCardCTA
									loading={loading as boolean}
									developer={developer !== "" || undefined ? developer : created_by.business_profile_id.title}
									published_status={published_status}
									dispatch={dispatch}
									session={session ? session : undefined}
									slug={slug as string}
									title={title as string}
									created_by={created_by}
									show_contact_details={show_contact_details}
									isEditor={isEditor}
									isBoosted={BoostingCheck}
								/>
							) : null} */}
						</SubTextContainer>
					</ServiceCardInfoContainer>
				</ServiceCardContainer>
			</ServiceLink>
		</React.Fragment>
	);
};

export default ServiceCard;
